* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-family: "Courier New", Courier, monospace;
  font-size: 62.5%;
  background-color: black;
}

body {
  height: 98vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  display: block;
}

h1 {
  font-size: 3.6rem;
  color: white;
}

h2 {
  font-size: 2.8rem;
  color: white;
  text-align: center;
}

div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.response {
  margin: 0 0 5rem 0;
}

.question {
  color: white;
  width: 65%;
  text-align: center;
  font-size: 1.7rem;
  margin: 2.5rem 0 1rem 0;
}

.textarea {
  font-size: 1.8rem;
  width: 40rem;
  height: 10rem;
  color: black;
  font-weight: 500;
  padding: 1rem;
  border-radius: 0.5rem;
}

.more-text {
  color: white;
  text-align: center;
  font-size: 3.8rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 800;
  outline: none;
  border: none;
}

.text {
  display: block;
  text-align: center;
  color: black;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  text-align: center;
  font-size: 2rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 800;
  outline: none;
  border: none;
  width: 50%;
  margin: -4rem 25%;
}

.textarea {
  resize: none;
  height: auto;
  overflow-y: hidden;
}

.textarea:focus {
  outline: none;
}

.button {
  display: flex;
  background-color: white;
  color: black;
  font-family: "Courier New", Courier, monospace;
  border-radius: 10px;
  font-weight: 800;
  font-size: 2.4rem;
  padding: 10px;
  cursor: pointer;
  outline: none;
  border: 0;
  margin: 1.5rem auto 2rem auto;
}

.button:active {
  background-color: rgb(44, 44, 44);
}

@media (max-width: 920px) {
  .text {
    width: 80%;
    margin: -4rem 10%;
  }
}
